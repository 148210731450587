import React from 'react'
import philosophyAnsMissionIcon from '../../images/philosophyAnsMission.svg'
import FadeIn from 'react-fade-in'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
const PhilosophyAndMission = () => {
    return (
        <section className=" py-4">
            <div className="grid grid-cols-1 md:grid-cols-2 max-w-screen-xl w-4/5 md:w-full m-auto">
                <div className="mb-2 w-3/5 m-auto lg:w-2/3">
                    <img src={philosophyAnsMissionIcon}/>
                </div>

                <div className="mx-4 flex justify-center items-center">
                    <FadeIn delay="300" transitionDuration="700">
                        <p className="text-xl sm:text-3xl pt-2 font-bold text-left">Our Philosphy and Mission</p>
                        <div className="mb-3  m-auto text-left">
                        
                            <p className="text-lg"> <CheckCircleIcon fontSize="large" className="mr-2 text-yellowColor"/> We use our experience in the field to identify gaps and create solutions that solve daily challenges and optimize your valuable time. </p>
                            <p className="text-lg"> <CheckCircleIcon fontSize="large" className="mr-2 text-yellowColor"/> We leverage the latest technological advancements to build the next generation of products, increasing the quality while driving costs down for the customer. </p>
                        </div>
                    </FadeIn>
                </div>
            </div>
        </section>
    )
}
export default PhilosophyAndMission