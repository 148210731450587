import React from 'react'
import Slide from 'react-reveal/Slide'
import aboutUs from '../../images/aboutUs.svg'
const HeaderCompany = () => {
    return (
        <header className="animate-fade-in-up bg-no-repeat bg-center bg-cover resize bg-bgColor">
            <div className="py-14 md:py-28 w-4/5 md:w-full max-w-screen-xl m-auto">
                <div className="grid grid-cols-1 md:grid-cols-2">
                    <div>
                        <Slide left cascade>
                            <div className="mx-2 text-center">
                                <p className="mb-4 text-left font-bold text-xl sm:text-3xl">
                About Us
                                </p>

                                <div className="mb-3 text-left text-lg" >
                 As Software Boutique Company we strive to design and develop <br/> innovative solutions to make your life easy and be more productive, <br/> supported by the best customer service.
                                </div>
                            </div>
                        </Slide>
                    </div>
                    <div className="w-3/5 m-auto lg:w-1/2">
                        <img src={aboutUs} />
                    </div>
                </div>
            </div>
        </header>
    )
}
export default HeaderCompany