import React, { useState } from 'react'

import Layout from '../components/layout'
import Seo from '../components/seo'
import SubscribeModal from '../components/modals/subscribeModal'
import Founders from '../components/companyComponents/founders'
import PhilosophyAndMission from '../components/companyComponents/philosophyAndMission'
import HeaderCompany from '../components/companyComponents/headerCompany'

const Company = () => {
    const [ modalIsOpen, setModalIsOpen ] = useState(false) 
    return (
        <div>
            <Layout>
                <Seo title="Company" description="BricoBrowser associated with Ricta Technologies" />
                <HeaderCompany/>
                <PhilosophyAndMission/>
                <Founders/>             
            </Layout>
            {modalIsOpen && <SubscribeModal modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen}/>}
        </div>
    )

}
export default Company